import { Box } from "@chakra-ui/react";
import Div100vh from "react-div-100vh";

const ScreenContainer = ({ children, fullHeight }) => {
  return (
    <>
      {fullHeight ? (
        <Div100vh
          style={{
            overflow: "hidden",
            overflowY: "hidden",
          }}
        >
          <Box
            h="100%"
            w="100vw"
            maxW="1000px"
            p={6}
            style={{
              background: `url('../../../rkv_bg.png')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              margin: "0 auto",
            }}
          >
            {children}
          </Box>
        </Div100vh>
      ) : (
        <Box
          h="100%"
          w="100vw"
          maxW="1000px"
          p={6}
          style={{
            background: `url('../../../rkv_bg.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            margin: "0 auto",
          }}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default ScreenContainer;
