import Link from 'next/link';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
  Box,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
  Text,
} from "@chakra-ui/react";
import {IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "next-i18next";

const Modal = ({ title, isOpen, onClose, menuItems, logout, router }) => {
  const { t } = useTranslation("menu");
  return (
    <ChakraModal motionPreset="scale" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="transparent"
        m={5}
        boxShadow="3px 3px #78C3C9"
      >
        <ModalCloseButton top={3} size="md" />
        <ModalHeader bg="#232F3A" color="white" textAlign="center">
          {title}
        </ModalHeader>
          <ModalBody backgroundColor="white" border="5px solid #232F3A">
            <VStack spacing={0} justifyContent="flex-start" alignItems="flex-start">
            <Menu autoSelect={false}>
              <MenuButton
                bg="transparent"
                _selected={{
                  backgroundColor: "transparent",
                  border: 0,
                  outline: 0,
                }}
                _active={{
                  backgroundColor: "transparent",
                  border: 0,
                  outline: 0,
                }}
                _hover={{
                  backgroundColor: "transparent",
                  border: 0,
                  outline: 0,
                }}
                as={Button}
                rightIcon={<IoIosArrowDown color="black" />}
              >
                <img
                  style={{
                    height: "1.5em",
                    border: "1px solid #2f373e",
                    borderRadius: "50%",
                  }}
                  src={`../../../${router.locale}.svg`}
                />
              </MenuButton>
              <MenuList bg="buttonDark" color="white">
                <Link href="/overview" locale="en">
                  <MenuItem
                    icon={
                      <img
                        style={{
                          height: "1.5em",
                          border: "1px solid #2f373e",
                          borderRadius: "50%",
                        }}
                        src="../../../en.svg"
                      />
                    }
                    bg="buttonDark"
                    _hover="buttonDark"
                  >
                    {t("english")}
                  </MenuItem>
                </Link>
                <Link href="/overview" locale="pl">
                  <MenuItem
                    icon={
                      <img
                        style={{
                          height: "1.5em",
                          border: "1px solid #2f373e",
                          borderRadius: "50%",
                        }}
                        src="../../../pl.svg"
                      />
                    }
                    bg="buttonDark"
                    _hover="buttonDark"
                  >
                    {t("polish")}
                  </MenuItem>
                </Link>
                <Link href="/overview" locale="no">
                  <MenuItem
                    icon={
                      <img
                        style={{
                          height: "1.5em",
                          border: "1px solid #2f373e",
                          borderRadius: "50%",
                        }}
                        src="../../../no.svg"
                      />
                    }
                    bg="buttonDark"
                    _hover="buttonDark"
                  >
                    {t("norwegian")}
                  </MenuItem>
                </Link>
              </MenuList>
            </Menu>
            <Button bg="transparent" color="baseDark" fontWeight="500"
            borderBottom="1px solid black" borderRadius="none"
            onClick={logout}><Link href="/" locale={router.locale}>{t("option2")}</Link></Button>
            </VStack>
          </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
