import { Flex, Box, Text, Input, Center } from "@chakra-ui/react";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

const TextField = ({
  label,
  button,
  center,
  placeholder,
  onKeyUp,
  value,
  handleChange,
  isInvalid,
  defaultValue,
  defaultCountry,
  handleCountryChange,
  otp,
}) => {
  return (
    <Box>
      <Text fontSize="sm" fontWeight={600}>
        {label}
      </Text>
      <Flex borderWidth={2} borderColor="black">
        {button && (
          <Box
            borderWidth={1}
            borderBottom={0}
            borderLeft={0}
            borderTop={0}
            borderColor="grey"
            width={20}
            bgColor="white"
          >
            {/*<DropdownMenu />*/}
            <Center height="100%">
              {/* <Text fontWeight="bold">+47</Text> */}
              <Input
                type="text"
                textAlign={center ? "center" : ""}
                border={0}
                size="lg"
                bgColor="white"
                isInvalid={isInvalid}
                placeholder="+47"
                onChange={handleCountryChange}
                value={defaultCountry}
              />
            </Center>
          </Box>
        )}
        {otp ? (
          <Input
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            textAlign={center ? "center" : ""}
            border={0}
            size="lg"
            bgColor="white"
            isInvalid={isInvalid}
            placeholder={placeholder}
            onChange={handleChange}
            value={defaultValue}
            onKeyUp={onKeyUp && ((event) => onKeyUp(event))}
          />
        ) : (
          <Input
            type="tel"
            textAlign={center ? "center" : ""}
            border={0}
            size="lg"
            bgColor="white"
            isInvalid={isInvalid}
            placeholder={placeholder}
            onChange={handleChange}
            value={defaultValue}
            onKeyUp={onKeyUp && ((event) => onKeyUp(event))}
          />
        )}
      </Flex>
    </Box>
  );
};

export default TextField;
