import { Button } from "@chakra-ui/react";

const MainButton = ({ text, loadingText, isLoading, onClick }) => {
  return (
    <Button
      isLoading={isLoading}
      loadingText={loadingText}
      colorScheme="mainButton"
      borderRadius={0}
      size="lg"
      w="100%"
      bg="mainButton"
      fontWeight={500}
      shadow="3px 3px #78C3C9"
      textTransform="uppercase"
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default MainButton;
