import Link from "next/link";
import { useRouter } from "next/router";
import { useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  Flex,
  Box,
  Image,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HiMenu } from "react-icons/hi";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useAuth } from "../../hooks/api";
import Modal from "../Modal/Modal";

const Header = ({ navigateBack, showMenu, showLanguages }) => {
  const { t } = useTranslation("menu");
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout } = useAuth();

  const handleButtonClick = () => {
    router.back();
  };

  return (
    <>
      <Flex justify="space-between" pb={8}>
        <Box>
          {navigateBack && (
            <IconButton
              size="sm"
              backgroundColor="transparent"
              color="black"
              aria-label="Gå tilbake"
              onClick={handleButtonClick}
              icon={<IoIosArrowBack size={20} />}
            />
          )}
        </Box>
        <Box
          position="absolute"
          top={10}
          left="50%"
          transform="translate(-50%, -50%);"
        >
          <Image src="statsbygg.png" />
        </Box>
        <Flex>
          {showMenu && (
            <IconButton
              size="sm"
              backgroundColor="transparent"
              color="black"
              aria-label="Åpne meny"
              onClick={onOpen}
              icon={<HiMenu size={20} />}
            />
          )}
          {showLanguages && (
            <Menu autoSelect={false}>
              <MenuButton
                bg="transparent"
                _selected={{
                  backgroundColor: "transparent",
                  border: 0,
                  outline: 0,
                }}
                _active={{
                  backgroundColor: "transparent",
                  border: 0,
                  outline: 0,
                }}
                _hover={{
                  backgroundColor: "transparent",
                  border: 0,
                  outline: 0,
                }}
                as={Button}
                rightIcon={<IoIosArrowDown color="black" />}
              >
                <img
                  style={{
                    height: "1.5em",
                    border: "1px solid #2f373e",
                    borderRadius: "50%",
                  }}
                  src={`../../../${router.locale}.svg`}
                />
              </MenuButton>
              <MenuList bg="buttonDark" color="white">
                <Link href="/" locale="en">
                  <MenuItem
                    icon={
                      <img
                        style={{
                          height: "1.5em",
                          border: "1px solid #2f373e",
                          borderRadius: "50%",
                        }}
                        src="../../../en.svg"
                      />
                    }
                    bg="buttonDark"
                    _hover="buttonDark"
                  >
                    {t("english")}
                  </MenuItem>
                </Link>
                <Link href="/" locale="pl">
                  <MenuItem
                    icon={
                      <img
                        style={{
                          height: "1.5em",
                          border: "1px solid #2f373e",
                          borderRadius: "50%",
                        }}
                        src="../../../pl.svg"
                      />
                    }
                    bg="buttonDark"
                    _hover="buttonDark"
                  >
                    {t("polish")}
                  </MenuItem>
                </Link>
                <Link href="/" locale="no">
                  <MenuItem
                    icon={
                      <img
                        style={{
                          height: "1.5em",
                          border: "1px solid #2f373e",
                          borderRadius: "50%",
                        }}
                        src="../../../no.svg"
                      />
                    }
                    bg="buttonDark"
                    _hover="buttonDark"
                  >
                    {t("norwegian")}
                  </MenuItem>
                </Link>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
      <Modal
        title={t("title")}
        isOpen={isOpen}
        onClose={onClose}
        logout={logout}
        router={router}
      />
    </>
  );
};

export default Header;
