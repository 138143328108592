import React, { useEffect, useState } from "react";
import { Flex, Center, Box, Text, Checkbox, Image } from "@chakra-ui/react";
import { useLMS } from "../../hooks/api";

const CourseCard = ({
  title,
  text,
  completed,
  image,
  url,
  completedText,
  onClick,
  id,
}) => {
  const { getAssetUrl } = useLMS();
  const [imageUrl, setImageUrl] = useState();
  const openLink = () => {
    window.open(url, "_blank");
  };

  const fetchCourseAssets = async (id) => {
    const url = await getAssetUrl(id);
    setImageUrl(url.data.attributes.delivery);
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchCourseAssets(id);
  }, [id]);

  return (
    <>
      {url ? (
        <a
          style={{ width: "100%", textDecoration: "none", cursor: "pointer" }}
          target="_blank"
          href={url}
        >
          <Flex
            justify="space-between"
            px={2}
            bg="#232F3A"
            height="7em"
            width="100%"
            shadow={completed ? "3px 3px #78C3C9" : "3px 3px #FFDD12"}
            //onClick={openLink}
          >
            <Box height="100%" width="5em">
              <Image objectFit="cover" src={image} />
            </Box>
            <Flex
              direction="column"
              justify="space-between"
              py={2}
              textAlign="right"
            >
              <Box>
                <Text
                  textTransform="uppercase"
                  fontSize="1.15em"
                  fontWeight="bold"
                  color="white"
                >
                  {title}
                </Text>
                <Text textTransform="uppercase" color="white" fontSize="0.8em">
                  {text}
                </Text>
              </Box>
              <Flex align="center" justify="flex-end">
                <Text pr={2} color={completed ? "#78C3C9" : "#FFDD12"}>
                  {completed ? completedText[0] : completedText[1]}
                </Text>
                <Checkbox
                  size="lg"
                  isReadOnly
                  isChecked={completed}
                  borderColor={completed ? "#78C3C9" : "#FFDD12"}
                  colorScheme={completed ? "#78C3C9" : "#FFDD12"}
                />
              </Flex>
            </Flex>
          </Flex>
        </a>
      ) : (
        <Flex
          justify="space-between"
          px={2}
          bg="#232F3A"
          height="7em"
          width="100%"
          shadow={completed ? "3px 3px #78C3C9" : "3px 3px #FFDD12"}
          onClick={onClick}
          _hover={{ cursor: "pointer" }}
        >
          <Flex
            height="100%"
            width="5em"
            minWidth="4em"
            justify="center"
            align="center"
          >
            <Image objectFit="cover" src={imageUrl} />
          </Flex>
          <Flex
            direction="column"
            justify="space-between"
            ml="0.5em"
            py={2}
            textAlign="right"
          >
            <Box>
              <Text
                textTransform="uppercase"
                fontSize="1.15em"
                fontWeight="bold"
                color="white"
              >
                {title}
              </Text>
              <Text textTransform="uppercase" color="white" fontSize="0.75em">
                {text}
              </Text>
            </Box>
            <Flex align="center" justify="flex-end">
              <Text
                fontWeight="bold"
                pr={2}
                color={completed ? "#78C3C9" : "#FFDD12"}
              >
                {completed ? completedText[0] : completedText[1]}
              </Text>
              <Checkbox
                size="lg"
                isReadOnly
                isChecked={completed}
                borderColor={completed ? "#78C3C9" : "#FFDD12"}
                colorScheme={completed ? "#78C3C9" : "#FFDD12"}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CourseCard;
