import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
  Button,
} from "@chakra-ui/react";

const DropdownMenu = () => {
  return (
    <Menu size="lg">
      <MenuButton color="white" borderColor="black" borderRight={1} as={Button}>
        Norsk
      </MenuButton>
      <MenuList>
        <MenuItem color="white">Svensk</MenuItem>
        <MenuItem color="white">Dansk</MenuItem>
        <MenuItem color="white">Finsk</MenuItem>
        <MenuItem color="white">Russisk</MenuItem>
        <MenuItem color="white">Spansk</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default DropdownMenu;
